




























import { Vue, Component } from 'vue-property-decorator';
import FreeTrialDlg from './FreeTrialDlg.vue';
import utils from '@/utils/index';
import { loadingApply } from '@/api/home';

const sourceMap: string = process.env.NODE_ENV ? process.env.NODE_ENV : 'dev';
@Component({
    name: 'Header',
    components: {
        FreeTrialDlg
    }
})
export default class Header extends Vue {
    private showFreeTrailDlg = false;
    private domainName:any = {
        development: {
            url: 'http://seller.test.jimuitech.com/#/home/login'
        },
        dev: {
            url: 'http://seller.test.jimuitech.com/#/home/login'
        },
        test: {
            url: 'http://seller.test.jimuitech.com/#/home/login'
        },
        uat: {
            url: 'http://seller.uat.jimuitech.com/#/home/login'
        },
        stable: {
            url: 'http://seller.stable.jimuitech.com/#/home/login'
        },
        production: {
            url: 'https://seller.eggmalltech.com/#/home/login'
        }
    }

    private handleLogin() {
        // console.log('this.domainName[sourceMap].url', this.domainName[sourceMap]);
        utils.openUrl(`${this.domainName[sourceMap].url}`);
    }

    private handleFreeTrialDlg() {
        this.showFreeTrailDlg = true;
        loadingApply({ country: '1001' });
        window.gtag('event', 'free_trial');
        utils.fbq('trackCustom', 'free_trial');
    }

    private goHomePage() {
        console.log(this.$route);
        if (this.$route.name != 'Home') {
            this.$router.push({
                name: 'Home'
            });
        }
    }

    private jumpToQandA() {
        const parent = this.$parent as any;
        parent.handleSonJumpToQandA();
    }

}
