


































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { submitFreetrial, sendCode, getCodeList } from '@/api/home';
import { ElForm } from 'element-ui/types/form';
import utils from '@/utils/index';
@Component({
    name: 'FreeTrialDlg'
})
export default class FreeTrialDlg extends Vue {
    @Prop({
        default: false
    })
    showFreeTrailDlg!:boolean

    @Prop({
        default: 1
    }) systemType!: number;

    @Prop({
        default: 1
    }) submitSource!: number;

    private showSuccessDlg = false;
    private sendBtnActive = false;
    private countDown = 60;
    private verify = true;
    private flag = '';
    private countryList:any = [];
    private freeTrialForm:any = {
        prex: '+66',
        verifyCode: '',
        mobile: '',
        name: '',
        detailAddress: '',
        email: '',
        contactPerson: ''
    };

    mounted():void {
        getCodeList('erp').then((res:any) => {
            // console.log(res);
            this.countryList = (res.data || []).map((item: any) => {
                item.countryFlag = item.countryFlag.replace('https://image-cdn.jimuitech.com', 'https://images.whats-egg.com');
                item.countryFlag = item.countryFlag.replace('https://image-cdn.eggmalltech.com', 'https://images.whats-egg.com');
                return item;
            });
            this.flag = this.countryList[0].countryFlag;
        });
    }

    get freeTrialRules():any {
        const mobileValidator = (rule:any, value:string, callback:any) => {
            const country = this.countryList.filter((item:any) => item.areaCode === this.freeTrialForm.prex.slice(1));
            const mobileRule = new RegExp(country[0].mobileRule);
            if (!mobileRule.test(value)) {
                callback(this.$i18n.t('landingWeb.checkNumberFormat'));
                this.sendBtnActive = false;
            } else {
                callback();
                this.sendBtnActive = true;
            }
        };

        const emailValidator = (rule:any, value:string, callback:any) => {
            if (!value) {
                callback();
            }
            const isValidEmail = (str:string) => /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(str);
            if (!isValidEmail(value)) {
                callback(this.$i18n.t('landingWeb.checkFormat'));
            } else {
                callback();
            }
        };

        const codeValidator = (rule:any, value:string, callback:any) => {
            const isValidCode = (str: string) => /^\d{4}$/.test(str);
            if (!isValidCode(value)) {
                callback(this.$i18n.t('landingWeb.checkFormat'));
            } else {
                callback();
            }
        };

        return {
            mobile: [
                { required: true, message: this.$i18n.t('landingWeb.fieldRequired'), trigger: 'blur' },
                { validator: mobileValidator, trigger: 'blur' }
            ],
            name: [
                { required: true, message: this.$i18n.t('landingWeb.fieldRequired'), trigger: 'blur' }
            ],
            verifyCode: [
                { required: true, message: this.$i18n.t('landingWeb.fieldRequired'), trigger: 'blur' },
                { validator: codeValidator, trigger: 'blur' }
            ],
            email: [
                { validator: emailValidator, trigger: 'blur' }
            ]
        };
    }

    private changeAreaCode() {
        this.flag = this.countryList.filter((item:any) => item.areaCode === this.freeTrialForm.prex.slice(1))[0].countryFlag;
    }

    private handleSubmit() {
        (this.$refs.freeTrialForm as ElForm).validate((valid) => {
            if (valid) {
                submitFreetrial({
                    ...this.freeTrialForm,
                    prex: this.freeTrialForm.prex.slice(1),
                    submitSource: this.submitSource,
                    applySystemType: this.systemType
                }).then((res:any) => {
                    if (res.code === '200') {
                        this.handleClose();
                        this.showSuccessDlg = true;
                        window.gtag('event', 'free_trial_submit');
                        utils.fbq('trackCustom', 'free_trial_submit');
                    } else {
                        this.$message.error(res.message);
                    }
                });
            }
        });
    }

    private handleSend() {
        if (!this.freeTrialForm.mobile) {
            return;
        }
        const timer = setInterval(() => {
            this.countDown--;
            this.verify = false;
            if (this.countDown === 0) {
                clearInterval(timer);
                this.verify = true;
                this.countDown = 60;
            }
        }, 1000);
        sendCode({
            prefix: this.freeTrialForm.prex.slice(1),
            cellphone: this.freeTrialForm.mobile
        }, 'erp').then((res:any) => {
            if (res.code === '20049024') {
                this.$message.success(`${this.$i18n.t('landingWeb.verifySuccess')}`);
            } else {
                clearInterval(timer);
                this.verify = true;
                this.$message.error(res.message);
            }
        }).catch(() => {
            clearInterval(timer);
            this.verify = true;
        });
    }

    private handleClose() {
        this.$emit('close');
        this.freeTrialForm = {
            prex: '+66',
            verifyCode: '',
            mobile: '',
            name: '',
            detailAddress: '',
            email: ''
        };
    }
}
